<template>
  <a-tabs
    type="card"
    class="sm-mt-20"
    :active-key="activeKey"
    @change="changeTab"
  >
    <a-tab-pane
      v-for="item in tabs"
      :key="item.path"
      :tab="item.name"
    >
      <router-view v-if="activeKey===item.path" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>

export default {
  data() {
    return {
      activeKey: '',
      tabs: [
        { path: '/task/comments', name: '评论审核' },
        { path: '/task/user_upload_records', name: '会员上传审核' },
        { path: '/task/reports', name: '举报审核' }
      ]
    };
  },
  watch: {
    $route: {
      handler: function() {
        this.activeKey = this.$route.path
      }
    }
  },
  created() {
    this.activeKey = this.$route.path
  },
  methods: {
    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ path: activeKey })
    }
  }
}
</script>
